@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Circular', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #222222;
}

/* Grid layout */
.course-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  padding: 24px;
}

/* Course listing styles */
.course-listing {
  font-family: 'Circular', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
}

.course-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 12px;
}

.course-info {
  margin-top: 12px;
}

.course-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4px;
}

.course-name {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  line-height: 20px;
}

.course-rating {
  display: flex;
  align-items: center;
}

.star {
  color: #FF385C;
  margin-right: 4px;
}

.course-location {
  font-size: 14px;
  color: #717171;
  margin: 0 0 4px 0;
}

.course-price {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

/* Map marker styles */
.custom-div-icon {
  background: white;
  border: 1px solid #222222;
  color: #222222;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Map popup styles */
.leaflet-popup-content-wrapper {
  padding: 0;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.leaflet-popup-content {
  margin: 0;
  width: 280px !important;
}

.leaflet-popup-close-button {
  color: #fff;
  opacity: 0.7;
  transition: opacity 0.2s;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.leaflet-popup-close-button:hover {
  opacity: 1;
}

.leaflet-popup-tip-container {
  display: none;
}